<template>
  <section class="hero  is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <h3 class="title has-text-black">
            <span class="logo">Initials</span>
          </h3>
          <div class="box">
            <h3 class="title has-text-black">
              Login
            </h3>

            <div class="field">
              <div class="control">
                <input
                  v-model="loginForm.email"
                  class="input "
                  type="email"
                  placeholder="Email"
                  autofocus=""
                >
              </div>
            </div>

            <div class="field">
              <div class="control">
                <input
                  v-model="loginForm.password"
                  class="input "
                  type="password"
                  placeholder="Password"
                >
              </div>
            </div>

            <button
              class="button is-block is-info"
              @click="login"
            >
              Login
            </button>
          </div>
          <!-- <p class="has-text-grey">
            <a href="../">Sign Up</a> &nbsp;·&nbsp;
            <a href="../">Forgot Password</a> &nbsp;·&nbsp;
            <a href="../">Need Help?</a>
          </p> -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      loginForm: {
        email: "",
        password: ""
      },
      loading: false,
      snackbar: false,
      snackBarMessage: "",
      passwordType: "password",
      loginRules: {
        email: [{ required: true, trigger: "blur" }],
        password: [{ required: true, trigger: "blur" }]
      }
    };
  },
  mounted() {
    let authRequired = window.$cookies.get("token");
    if (authRequired) {
      this.$router.replace(this.$route.query.redirect || "/");
    }
  },
  methods: {
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
    },
    showSnackBar(message) {
      this.snackBarMessage = message;
      this.snackbar = true;
    },
    login() {
      this.loading = true;
      axios
        .post("/auth/jwt/create/", {
          email: this.loginForm.email,
          password: this.loginForm.password
        })
        .then(response => {
          this.loading = false;
          if (response.status == 200) {
            this.$cookies.set("token", response.data.access);
            this.$cookies.set("refresh", response.data.refresh);
            this.$router.replace(this.$route.query.redirect || "/");
          }
        })
        .catch(() => {
          this.loading = false;
          this.showSnackBar("Try again later.");
        });
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
.login-container {
  margin: 0 auto;
  padding-top: 150px;
}
.login-container .el-input {
  display: inline-block;
  min-height: 100%;
  width: 100%;
}
.login-container .el-input input {
  background: transparent;
  border: 0px;
  -webkit-appearance: none;
  border-radius: 0px;
  padding: 12px 5px 12px 15px;
  height: 47px;
  caret-color: #fff;
}
.login-container .el-input input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #283443 inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #283443 inset !important;
  -webkit-text-fill-color: #fff !important;
}
.login-container .el-form-item {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.login-form {
  position: relative;
  width: 520px;
  max-width: 100%;
  padding: 80px 35px 0;
  overflow: hidden;
}

.login-logo {
  display: block;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  font-family: 'Pacifico', cursive;
  font-size: 45px;
  font-weight: 100;
}
</style>



