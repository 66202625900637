var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "hero  is-fullheight" }, [
    _c("div", { staticClass: "hero-body" }, [
      _c("div", { staticClass: "container has-text-centered" }, [
        _c("div", { staticClass: "column is-4 is-offset-4" }, [
          _vm._m(0),
          _c("div", { staticClass: "box" }, [
            _c("h3", { staticClass: "title has-text-black" }, [
              _vm._v(" Login ")
            ]),
            _c("div", { staticClass: "field" }, [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.loginForm.email,
                      expression: "loginForm.email"
                    }
                  ],
                  staticClass: "input ",
                  attrs: { type: "email", placeholder: "Email", autofocus: "" },
                  domProps: { value: _vm.loginForm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.loginForm, "email", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "field" }, [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.loginForm.password,
                      expression: "loginForm.password"
                    }
                  ],
                  staticClass: "input ",
                  attrs: { type: "password", placeholder: "Password" },
                  domProps: { value: _vm.loginForm.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.loginForm, "password", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c(
              "button",
              {
                staticClass: "button is-block is-info",
                on: { click: _vm.login }
              },
              [_vm._v(" Login ")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "title has-text-black" }, [
      _c("span", { staticClass: "logo" }, [_vm._v("Initials")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }